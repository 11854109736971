<template>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard('ts801')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        <i class="icofont icofont-link font-primary">&nbsp;</i> Scenario 801:
        Successful Charging</a
      >
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard('ts802')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        <i class="icofont icofont-link font-primary">&nbsp;</i> Scenario 802:
        Charging Session Overshoot
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard('ts803')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        <i class="icofont icofont-link font-primary">&nbsp;</i> Scenario 803:
        Charging Session Creation Failure
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "EVChargingWithAutoAcquireTestCasesList",
  methods: {
    scrollToCard(cardId) {
      this.$emit("scrollToCard", cardId);
    },
  },
};
</script>
