<template>
  <Breadcrumbs main="rydpay APIs" title="Release Notes & Updates"/>

  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <p class="mb-0">Welcome to our Release Notes & Updates section. Here, we chronicle the ongoing
              evolution of our API, detailing the introduction of new features, enhancements, and critical
              updates. Our aim is to keep you fully informed about the latest refinements and improvements,
              ensuring seamless integration and utilization of our API in your projects and workflows.
            </p>
            <br/>
            <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"></i>
              <p>You may have noticed frequent version changes in ryd APIs.</p> <br/>
              <p>At ryd, these updates are part of our continuous integration and delivery (CI/CD) process. We increment the API versions primarily when improvements are made to the backend logic that do not affect the interfaces you use. This ensures that your integration remains stable and unaffected by our internal updates.
              </p><br/>
               <p>Please rest assured that all changes affecting partner interfaces will be comprehensively documented on this page. Ryd is committed to transparency and will not introduce any breaking changes without first informing our partners and obtaining your confirmation.
                </p>
                <br/>
                <p>Stay tuned to this page for all updates and ensure your systems align with our latest improvements.</p>
              <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </div>
      </div>


      <div class="col-sm-12 col-md-12">
        <div class="card shadow-sm">
          <div class="card-header bg-primary text-white">
            <div class="d-flex align-items-center">
              <i class="bi bi-code-square me-2 fs-4"></i>
              <h5 class="mb-0">Version 6.76.0</h5>
              <span class="badge bg-light text-primary ms-auto">New Feature</span>
            </div>
          </div>

          <div class="card-body">
            <h5 class="mt-4 border-bottom pb-2"><i class="bi bi-phone-fill me-2"></i>Feature Overview</h5>
            <strong>WashOTC-QR</strong> Code Purchase is now available for seamless customer redemption.

            <h5 class="mt-4 border-bottom pb-2"><i class="bi bi-info-circle me-2"></i>Feature Description</h5>
            <p>We've enhanced the purchase flow to support <strong>WashOTC redemption via QR codes</strong>, enabling a contactless experience for your customers. This update streamlines the redemption process at supporting stations.</p>

            <h5 class="mt-4 border-bottom pb-2"><i class="bi bi-arrow-repeat me-2"></i>API Changes</h5>
            <div class="card bg-light mb-3">
              <div class="card-body">
                <h6 class="card-title">
                  <span class="badge bg-success">GET</span>
                  <code>/order/{orderId}</code>
                </h6>
                <p class="text-dark">When retrieving order details, the response now includes:</p>
                <ul class="list-group list-group-flush mb-3 text-dark">
                  <li class="list-group-item bg-light text-dark">
                    <i class="bi bi-check-circle-fill text-success me-2"></i>
                    <code>redemptionCodes</code> array containing purchased <code>WashOTC</code> items
                  </li>
                  <li class="list-group-item bg-light text-dark">
                    <i class="bi bi-check-circle-fill text-success me-2"></i>
                    <code>additionalDisplays</code> array with base64-encoded QR code images when supported
                  </li>
                </ul>
              </div>
            </div>

            <h5 class="mt-4 border-bottom pb-2"><i class="bi bi-file-code me-2"></i>Response Examples</h5>

            <div class="card bg-light mb-3">
              <h5 class="card-header text-dark">Generic Structure</h5>
              <div class="card-body">
          <pre class="bg-light text-dark p-3 rounded border"><code>{
 "_id": "2d00c04a-e9f6-44c0-8e35-71f1da5a2eda",
  "type": "AUTOMATIC",
  "state": "COMPLETED",
  "products": [
    {
      "_id": "product1234",
      "name": "a product name",
      "type": "CAR_WASH",
      "redemptionCodes": [
        {
          "uuid": "example-uuid",
          "isRedeemed": false,
          "code": "123456789",
          "displayTypes": ["TEXT"],
          "quantity": 1,
          "type": "DIGIT",
          "additionalDisplays": [
            {
              "code": "data:image/png;base64,&lt;base64-encoded-QR-code&gt;",
              "type": "QR"
            }
          ]
        }
      ]
    }
  ],
  ...
}</code></pre>
              </div>
            </div>

            <div class="card bg-light mb-3">
              <h5 class="card-header text-dark">Real Test Data with QR Code</h5>
              <div class="card-body">
          <pre class="bg-light text-dark p-3 rounded border"><code>{
  "redemptionCodes": [
    {
      "uuid": "4daacc14-a52e-4de3-86ac-b38c921492de",
      "isRedeemed": false,
      "code": "848637971",
      "displayTypes": ["TEXT"],
      "type": "DIGIT",
      "additionalDisplays": [
        {
          "code": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAklEQVR4AewaftIAAAK5SURBVO3BQW7kQAwEwSxC//9yro88NSBIM2sTjIg/WGMUa5RijVKsUYo1SrFGKdYoxRqlWKMUa5RijVKsUYo1SrFGKdYoxRrl4qEkfJPKHUnoVLokdCpdEr5J5YlijVKsUYo1ysXLVN6UhCdUuiR0KneovCkJbyrWKMUapVijXHxYEu5QuSMJnUqXhE7lTUm4Q+WTijVKsUYp1igXwyShU+mScKLylxVrlGKNUqxRijVKsUYp1ijFGuXiw1T+pyR0Kl0SnlD5TYo1SrFGKdYoFy9Lwv+k0iXhTUn4zYo1SrFGKdYoFw+p/CVJuEPlLynWKMUapVijXDyUhE6lS8KbVDqVE5WTJJwk4U0qn1SsUYo1SrFGufjPVO5IwolKl4RO5Q6VLgmdym9SrFGKNUqxRok/eFESOpWTJNyhcpKEO1S6JHQqJ0m4Q+WTijVKsUYp1ijxBw8koVPpktCpnCShUzlJwonKm5Jwh0qXhE7lTcUapVijFGuUi5cloVPpknCi0iWhU+lUuiR0SThR6ZLQqXQqXRJ+k2KNUqxRijVK/MEfloRO5SQJT6icJOEJlSeKNUqxRinWKPEHDyThm1ROktCpnCShU+mScKLSJaFT6ZLQqbypWKMUa5RijXLxMpU3JeEkCZ3KSRJOknCi8oRKl4RO5YlijVKsUYo1ysWHJeEOlSeS0Kl0Kl0SOpWTJHQqnUqXhE6lU3lTsUYp1ijFGuViGJUuCZ3KN6l8U7FGKdYoxRrlYpgknCShU+mS0Kl0Kl0STlS+qVijFGuUYo1y8WEqn6TSJaFTuUPlJAknKl0STlTeVKxRijVKsUa5eFkSvikJnUqXhBOVLgmdSqfSJeEOlU8q1ijFGqVYo8QfrDGKNUqxRinWKMUapVijFGuUYo1SrFGKNUqxRinWKMUapVijFGuUYo3yD5aqINax5jc2AAAAAElFTkSuQmCC",
          "type": "QR"
        }
      ]
    }
  ]
}</code></pre>

                <br/>
                <h6 class="mt-4 border-bottom pb-2 text-dark"><i class="bi bi-flask me-2"></i>Test QR Code</h6>
                <p class="text-dark">The QR code below is decoded from the base64 string above. Please use it to verify that your base64 handling and QR code rendering work correctly.</p>
                <div class="text-center">
                  <img src="./../../../assets/images/ryd/qr/example-qr-code.png" alt="Test QR Code for validation" class="img-thumbnail" style="width: 150px; height: 150px;">
                </div>


              </div>
            </div>


          </div>

          <div class="card-footer">
            <h6 class="mb-0"><i class="bi bi-calendar3 me-1"></i>: 2025-03-21</h6>
          </div>
        </div>
      </div>






      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header bg-success">
            <h5><i class="icofont icofont-id"></i>&nbsp;Version: 6.75.31</h5>
          </div>
          <div class="card-body">
            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Release Notes & Updates</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                To improve and streamline the order flow we deprecated the
                <code>PUT /v4/order/{orderId}/acquire</code> endpoint. Use <code>autoAcquire: true</code>
                on create order endpoint to automatically trigger acquire. With this flag after order authorization instead of calling acquire just
                keep on polling to get the final state of the order. The endpoint will be available until all API consumers start using the <code>autoAcquire: true</code>.
              </li>
            </ul>
            <br/>
          </div>
          <div class="card-footer">
            <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2025-01-09</h6>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header bg-primary">
            <h5><i class="icofont icofont-id"></i>&nbsp;Version: 6.56.11</h5>
          </div>
          <div class="card-body">
            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Release Notes & Updates</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                As part of our continuous commitment to enhancing functionality and versatility, we are excited to
                announce the integration of ev charging into our existing ryd.pay APIs. This enhancement
                involves two significant extensions:
                <br/>
                <br/>
                1. <code>GET /v4/pois</code>:
                This end point has been enhanced to provide detailed information on the price tariffs of EV charging stations (pois). EV Charging Stations(poi) will only be returned if the request parameter <code>showEvPois=true</code> is set.
                The new fields included are:
                <pre>
                  <code>
                    products[n].attributes.availableItems[m].priceElements
                  </code>
                </pre>
                <br/>
                2. <code>GET /v4/pois/:poiId</code>:
                This endpoint has been enhanced to provide detailed information on the price tariffs of one single EV charging station (poi). The new fields included are:
                <pre>
                  <code>
                    products[n].attributes.availableItems[m].priceElements
                  </code>
                </pre>
                <br/>
                3. <code>PUT /v4/order/:orderId/acquire/stop</code>: has been added for EV Charging
                <br/>
                4. <code>GET /v4/ongoing-orders</code>: has been added for EV Charging
                <br/>
              </li>

              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                For a detailed overview of these new fields and their definitions, please refer to the updated <a
                  href="/integration-guide/rydpay-apis/api-reference#/staging">API Reference</a> section.
              </li>

              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><b>Availability: </b> These updates are currently
                deployed on the staging environment and available on the production
                environment as of <strong>2024-06-28</strong>.
              </li>

            </ul>
            <br/>

            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Testing Process</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                The new fields for existing APIs and new APIs are backwards compatible because the new fields for existing APIs are only visible if a special query parameter is set.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                The EV charging integration documentation is available at <a href="/integration-guide/rydpay-apis/integration-pathways#ev-charging">EV Charging Integration Pathway</a>.
              </li>
            </ul>
            <br/>

          </div>
          <div class="card-footer">
            <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-06-28</h6>
          </div>
        </div>
      </div>


      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header bg-success">
            <h5><i class="icofont icofont-id"></i>&nbsp;Version: 6.52.1</h5>
          </div>
          <div class="card-body">
            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Release Notes & Updates</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Extended <code>GET /v4/order/{orderId}</code> API Response
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>We have enhanced the  <code>GET /v4/order/{orderId}</code> endpoint to include a new response object, <code>partnerExtraData</code>.
                This additional information contains data originally sent by partners during the <code>POST /v4/order/{orderId}/authorize</code> request.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>The <code>partnerExtraData</code> object provides deeper insights into partner transactions and includes the following fields:<br/>

                <ul class="list-group">
                  <li class="list-group-item">
                    <i class="fa fa-arrow-circle-right font-primary"></i>
                    <code>customerId</code>: Identifier for the customer as provided by the partner.
                  </li>
                  <li class="list-group-item">
                    <i class="fa fa-arrow-circle-right font-primary"></i>
                    <code>partnerOrderId</code>:  Unique order identifier from the partner.
                  </li>
                  <li class="list-group-item">
                    <i class="fa fa-arrow-circle-right font-primary"></i>
                    <code>paymentMethod</code>: Details about the payment method used in the transaction, including: <br/>
                    <code>cardBrand</code>: Type of card used, e.g., VISA. <br/>
                    <code>providerMethod</code>: Payment method provided by the service, e.g., DIRECT_CREDIT.
                  </li>
                </ul>

              </li>

            </ul>
            <br/>

          </div>
          <div class="card-footer">
            <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-04-15</h6>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header bg-primary">
            <h5><i class="icofont icofont-id"></i>&nbsp;Version: 6.44.4</h5>
          </div>
          <div class="card-body">
            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Release Notes & Updates</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                As part of our continuous commitment to enhancing functionality and versatility, we are excited to
                announce the integration of car wash products into our existing ryd.pay APIs. This enhancement
                involves two significant extensions:
                <br/>
                <br/>
                1. <code>GET /v4/order/{orderId}</code>:
                This endpoint has been enhanced to provide detailed information about purchased car wash products. The new fields included are:
                <pre>
                  <code>
                    products[n].redemptionCodes[m].startDate
                    products[n].redemptionCodes[m].expirationDate
                    products[n].redemptionCodes[m].quantity
                  </code>
                </pre>
                <br/>
                2. <code>GET /v4/pois/{poiId}</code>: This endpoint now also features details of the car wash products available. The new fields include:
                <pre>
                  <code>
                    products[].attributes.availableItems[].internalName
                    products[].attributes.availableItems[].rydId
                    products[].attributes.availableItems[].descriptionLines[]
                  </code>
                </pre>

              </li>

              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                For a detailed overview of these new fields and their definitions, please refer to the updated <a
                  href="/integration-guide/rydpay-apis/api-reference#/staging">API Reference</a> section.
              </li>

              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><b>Availability: </b> These updates are currently
                deployed on the staging environment and NOT yet available on the production
                environment as of <strong>2024-01-24</strong>.
              </li>

            </ul>
            <br/>

            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Testing Process</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                To ensure that the introduction of new fields in our API has not disrupted your existing implementations,
                we advise you to test using the gas station with the POI ID <code>fddcf2ef-0a9d-4613-a943-7d592b4689c6</code>.
                This specific POI ID will help you verify the integration of both fuel and car wash products and confirm that your systems are properly handling the updated data structure.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                Please use the following endpoint for testing: <code>GET /v4/pois/fddcf2ef-0a9d-4613-a943-7d592b4689c6</code>.
                This will return information on both fuel and car wash products, allowing you to check the compatibility of your implementations with the newly added fields.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                You can explore the car wash process in detail on ryd's web application within our staging environment.
                Start by visiting: <a href="https://rydpay.thinxcloud-staging.de/pay/postpay/wash?pid=fddcf2ef-0a9d-4613-a943-7d592b4689c6">ryd's Car Wash Process</a>.
              </li>
            </ul>
            <br/>

          </div>
          <div class="card-footer">
            <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-24</h6>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header bg-success">
            <h5><i class="icofont icofont-id"></i>&nbsp;Version: 6.44.3</h5>
          </div>
          <div class="card-body">
            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Release Notes & Updates</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Introduction of two new transitive order states:
                <strong>PAYMENT_AUTHORIZATION_STARTED</strong> and
                <strong>PRODUCT_ACQUISITION_STARTED</strong>. <br/> See <a
                  href="/integration-guide/rydpay-apis/guides#order-state-details">Order State Details</a> for an
                explanation of the two states.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>These states enhance order tracking and provide
                more
                detailed status updates. <br/> See <a href="/integration-guide/rydpay-apis/tutorials#enum-expansion">Best
                Practices for Enum Expansion</a> for details on handling these changes.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>To ensure backward compatibility and maintain a
                non-breaking change, these new states will be reflected
                only within the state-history of orders. The primary state field of the order will remain unchanged.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><b>Availability: </b> These updates are currently
                deployed on the staging environment and NOT yet available on the production
                environment as of <strong>2024-01-22</strong>.
              </li>
            </ul>
            <br/>


            <p class="mb-0"><i class="icofont icofont-info-square"></i>: Testing Process</p>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                To begin testing, refer to our <a href="/integration-guide/rydpay-apis/test-scenarios">Test
                Scenarios</a>. Specifically, execute a "happy flow" test case, e.g. TC 102. This will help you
                understand how the new state behaves under ideal conditions.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                Once you've executed the test case, you can retrieve the new state information. Send a GET request to
                the endpoint: <code>GET /v4/orders/{orderId}</code>.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>
                In the API response, you'll observe the new state.
                Below is an example of what the API response might look like, including the new states.
                <!-- Button to toggle the code block -->
                <button class="btn btn-success m-l-20" @click="showCode = !showCode">
                  {{ showCode ? 'Hide Full Response' : 'Show Full Response' }}
                </button>
              </li>
              <li class="list-group-item">

                <div v-if="showCode">
                <pre>
                  <code>
                    {
                    "_id": "323dd390-2657-40f1-bfbf-cc996dccfd01",
                    "type": "AUTOMATIC",
                    "state": "COMPLETED",
                    "thingId": "65aaad073154fa8c26c5948f",
                    "products": [
                        {
                            "_id": "c6fdbf7a-ce56-4877-b11e-e86b58f28ab3",
                            "name": "1",
                            "type": "GAS",
                            "attributes": {
                                "automaticPump": true,
                                "paymentAttributes": {
                                    "currencyType": "EUR",
                                    "description": "PremiumDiesel",
                                    "taxClass": 19,
                                    "unitPrice": {
                                        "amount": "1.666",
                                        "precision": 3,
                                        "currency": "EUR"
                                    },
                                    "netPrice": {
                                        "amount": "12.605",
                                        "precision": 3,
                                        "currency": "EUR"
                                    },
                                    "totalPrice": {
                                        "amount": "15",
                                        "precision": 0,
                                        "currency": "EUR"
                                    },
                                    "unit": "LTR",
                                    "quantity": 9.004
                                }
                            }
                        }
                    ],
                    "stateHistory": [
                        {
                            "state": "CREATED",
                            "message": "",
                            "createdAt": "2024-01-23T07:00:44.583Z",
                            "updatedAt": "2024-01-23T07:00:44.583Z"
                        },
                        {
                            "state": "PAYMENT_PENDING",
                            "message": "Payment started notification",
                            "createdAt": "2024-01-23T07:00:47.552Z",
                            "updatedAt": "2024-01-23T07:00:47.552Z"
                        },
                        {
                            "state": "PAYMENT_AUTHORIZATION_STARTED",
                            "message": "Payment authorization started.",
                            "createdAt": "2024-01-23T07:00:53.497Z",
                            "updatedAt": "2024-01-23T07:00:53.497Z"
                        },
                        {
                            "state": "PAYMENT_AUTHORIZED",
                            "message": "Payment authorized in AUTOMATIC flow",
                            "createdAt": "2024-01-23T07:00:53.536Z",
                            "updatedAt": "2024-01-23T07:00:53.536Z"
                        },
                        {
                            "state": "PRODUCT_ACQUISION_STARTED",
                            "message": "Product acquisition started.",
                            "createdAt": "2024-01-23T07:00:57.062Z",
                            "updatedAt": "2024-01-23T07:00:57.062Z"
                        },
                        {
                            "state": "PRODUCT_PENDING",
                            "message": "Product pending",
                            "createdAt": "2024-01-23T07:00:58.593Z",
                            "updatedAt": "2024-01-23T07:00:58.593Z"
                        },
                        {
                            "state": "PRODUCT_ACQUIRED",
                            "message": "Order successfully acquired",
                            "createdAt": "2024-01-23T07:01:04.604Z",
                            "updatedAt": "2024-01-23T07:01:04.604Z"
                        },
                        {
                            "state": "READY_FOR_INVOICE",
                            "message": "vouchers updated",
                            "createdAt": "2024-01-23T07:01:08.857Z",
                            "updatedAt": "2024-01-23T07:01:08.857Z"
                        },
                        {
                            "state": "COMPLETED",
                            "message": "Transaction completed",
                            "createdAt": "2024-01-23T07:01:08.948Z",
                            "updatedAt": "2024-01-23T07:01:08.948Z"
                        }
                    ],
                    "pollIntervalMs": 5000,
                    "loyaltyItems": [],
                    "vouchers": [],
                    "userInteraction": {
                        "isRequired": false
                    },
                    "totalAfterDiscounts": {
                        "precision": 2,
                        "currency": "EUR",
                        "amount": "15.00"
                    },
                    "totalBeforeDiscounts": {
                        "amount": "15.00",
                        "currency": "EUR",
                        "precision": 2
                    },
                    "totalDiscounts": {
                        "amount": "0.00",
                        "currency": "EUR",
                        "precision": 2
                    }
                }
                  </code>
                </pre>
                </div>
              </li>
            </ul>
            <br/>
          </div>
          <div class="card-footer">
            <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-22</h6>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
export default {
  keywords: ['Release Notes', 'API Updates', 'Version Changes', 'New Features', 'Bug Fixes', 'Performance Improvements', 'Deprecated Features', 'API Documentation', 'Change Log', 'Enhancements'],
  data() {
    return {
      // Reactive property to track visibility of the code block
      showCode: false,
    };
  },
};
</script>