<template>
  <Breadcrumbs main="rydpay APIs" title="Webhooks"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Overview of Webhooks</h5>
          </div>
          <div class="card-body">
            <p>
              Webhooks are automated messages sent from our system to your application when specific events occur.
              They allow your systems to receive real-time notifications about important events such as
              <b>order state changes, invoice generation</b>, and more.
            </p>
            <p>
              With webhooks, you don't need to continuously poll our API for updates. Instead, our system will
              automatically notify your application whenever relevant events happen.
            </p>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Supported Event Categories</h5>
          </div>
          <div class="card-body">
            <p>
              Our webhook system supports notifications for two main categories of events:
            </p>
            <ul>
              <li><strong>Order Events</strong>: Notifications about changes in order status</li>
              <li><strong>Invoice Events</strong>: Notifications about invoice and receipt generation</li>
            </ul>
            <p>
              You can choose to receive notifications for specific events within these categories or
              for all events in a category.
            </p>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Supported Event Types</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="font-primary">Order Events:</h6>
                <div class="table-responsive">
                  <table class="table table-bordered table-hover">
                    <thead>
                    <tr>
                      <th>Event Type</th>
                      <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><code>CREATED</code></td>
                      <td>When a new order is created</td>
                    </tr>
                    <tr>
                      <td><code>PAYMENT_PENDING</code></td>
                      <td>When payment is awaiting confirmation</td>
                    </tr>
                    <tr>
                      <td><code>PAYMENT_AUTHORIZED</code></td>
                      <td>When payment is authorized</td>
                    </tr>
                    <tr>
                      <td><code>PRODUCT_PENDING</code></td>
                      <td>When product delivery is pending</td>
                    </tr>
                    <tr>
                      <td><code>PRODUCT_ACQUIRED</code></td>
                      <td>When product is successfully acquired</td>
                    </tr>
                    <tr>
                      <td><code>COMPLETED</code></td>
                      <td>When order is successfully completed</td>
                    </tr>
                    <tr>
                      <td><code>REJECTED</code></td>
                      <td>When order is rejected</td>
                    </tr>
                    <tr>
                      <td><code>CANCELLED</code></td>
                      <td>When order is cancelled</td>
                    </tr>
                    <tr>
                      <td><code>PROBLEM</code></td>
                      <td>When order encounters an issue</td>
                    </tr>
                    <tr>
                      <td><code>FAILED</code></td>
                      <td>When order processing fails</td>
                    </tr>
                    <tr>
                      <td><code>READY_FOR_INVOICE</code></td>
                      <td>When order is ready for invoice generation</td>
                    </tr>
                    <tr>
                      <td><code>COMPLETED_MANUALLY</code></td>
                      <td>When order is manually marked as complete</td>
                    </tr>
                    <tr>
                      <td><code>PRODUCTS_AMENDED</code></td>
                      <td>When products in an order are modified</td>
                    </tr>
                    <tr>
                      <td><code>ORDER_AMENDED</code></td>
                      <td>When order details are amended</td>
                    </tr>
                    <tr>
                      <td><code>VOUCHERS_APPLIED</code></td>
                      <td>When vouchers are applied to an order</td>
                    </tr>
                    <tr>
                      <td><code>INVOICE_GENERATION_REQUESTED</code></td>
                      <td>When invoice generation is requested</td>
                    </tr>
                    <tr>
                      <td><code>ORDER_AMENDED_BY_PARTNER</code></td>
                      <td>When order is amended by a partner</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-12">
                <h6 class="font-primary">Invoice Events:</h6>
                <div class="table-responsive">
                  <table class="table table-bordered table-hover">
                    <thead>
                    <tr>
                      <th>Event Type</th>
                      <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><code>RECEIPT_GENERATED</code></td>
                      <td>When a receipt is generated</td>
                    </tr>
                    <tr>
                      <td><code>INVOICE_GENERATED</code></td>
                      <td>When an invoice is generated</td>
                    </tr>
                    <tr>
                      <td><code>READY_FOR_INVOICE</code></td>
                      <td>When an order is ready for invoice generation</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>


        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Setting Up Webhook Notifications</h5>
          </div>
          <div class="card-body">
            <p>
              Currently, webhook registration and URL configuration can only be done by the ryd team on your behalf.
            </p>
            <p>
              If you want to start receiving webhook notifications, please contact your ryd account manager with the following information:
            </p>
            <ul>
              <li>Your preferred webhook URL (must support HTTPS)</li>
              <li>Which event categories you're interested in (<code>order</code>, <code>invoice</code>, or both)</li>
              <li>Specific event types you want to receive, or if you want to receive all events in a category</li>
              <li>Any authentication requirements for your webhook endpoint</li>
            </ul>

            <p>
              <i class="fa fa-info-circle text-primary"></i> <strong>Coming Soon:</strong> In the near future, we plan to provide functionality directly on this portal that will allow you to register, update, or delete webhook settings yourself without contacting support.
            </p>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Webhook Payload Structure</h5>
          </div>
          <div class="card-body">
            <p>
              When an event occurs, our system sends a POST request to your registered URL with a JSON payload.
              The structure of this payload depends on the event category:
            </p>

            <p><strong>For Order Events:</strong></p>
            <pre><code>
{
  "orderUid": "9150f006-f75c-4744-b44f-49872d99f136",
  "link": "https://api.example.com/orders/9150f006-f75c-4744-b44f-49872d99f136",
  "orderState": "COMPLETED",
  "eventCategory": "order",
  "eventType": "COMPLETED"
}
            </code></pre>

            <p><strong>For Invoice Events:</strong></p>
            <pre><code>
{
  "orderUid": "9150f006-f75c-4744-b44f-49872d99f136",
  "downloadUrl": "https://tt4.thinxcloud.de/v4/invoices/9150f006-f75c-4744-b44f-49872d99f136",
  "eventCategory": "invoice",
  "eventType": "INVOICE_GENERATED"
}
            </code></pre>

            <p>
              You can use the <code>orderUid</code> to identify which order the notification is related to,
              and the <code>eventType</code> to determine what happened.
            </p>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Webhook Endpoint Requirements</h5>
          </div>
          <div class="card-body">
            <p>
              Your webhook endpoint should meet the following requirements:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Support HTTPS POST requests
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Accept JSON in the request body
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Respond with an HTTP success status code (e.g., 200 OK) to confirm receipt of the notification
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Process requests within a reasonable time (ideally under 5 seconds)
              </li>
            </ul>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Security Considerations</h5>
          </div>
          <div class="card-body">
            <p>
              Securing your webhook endpoint is important to ensure that you only process legitimate requests from our system.
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>HTTPS Required</strong>: Your webhook URL must use HTTPS to ensure secure transmission of data.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Authentication</strong>: Currently, you can include authentication credentials as query parameters in your webhook URL (e.g., <code>https://your-domain.com/webhook?token=your_secret_token</code>).
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Verification</strong>: Implement additional verification in your webhook handler to validate that requests are coming from our system.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>IP Whitelisting</strong>: For additional security, you can whitelist our IP addresses. Contact support for the current list of IPs used for webhook delivery.
              </li>
            </ul>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Reliability & Retry Mechanism</h5>
          </div>
          <div class="card-body">
            <p>
              We understand that webhook delivery reliability is critical for your operations. Our system includes a robust retry mechanism:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>If your endpoint is unavailable or returns an error, we will automatically retry the delivery.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>We attempt immediate retries followed by scheduled retries with increasing intervals.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Failed events are stored in our database and retried on an hourly schedule.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Each webhook notification will be retried up to 10 times before being marked as permanently failed.
              </li>
            </ul>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Best Practices</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Idempotent Processing</strong>: Design your webhook handler to be idempotent (able to receive the same notification multiple times without causing issues), as duplicate notifications may occur during retries.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Quick Response</strong>: Your endpoint should acknowledge receipt of the webhook quickly, even if you process the data asynchronously.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Logging</strong>: Implement detailed logging of received webhooks for troubleshooting and audit purposes.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Asynchronous Processing</strong>: Consider using a queue to process webhook notifications asynchronously to prevent processing delays.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i><strong>Testing</strong>: Before going to production, thoroughly test your webhook handler with various event types to ensure correct processing.
              </li>
            </ul>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Troubleshooting & Support</h5>
          </div>
          <div class="card-body">
            <p>
              If you're experiencing issues with webhook deliveries or have questions about implementation:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Check your webhook endpoint logs to verify if requests are being received.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Ensure your endpoint is properly responding with HTTP 200 status codes.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Verify that your firewall or security settings are not blocking our webhook requests.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Contact our support team for assistance with webhook configuration or delivery issues.
              </li>
            </ul>
          </div>
        </div>
        <br/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RydPayWebhooks",
  keywords: ['Webhook', 'Callback', 'Notification', 'Order Events', 'Invoice Events', 'Integration', 'API', 'Real-time'],
}
</script>